<template>
  <v-navigation-drawer
    permanent
    expand-on-hover
    v-model="navegacion_menu"
    style="
      z-index: 99999999999;
      height: 100vh;
    "
    class="menu"
    dark
    fixed
  >
    <v-list>
        <v-list-item class="px-2">
        <v-list-item-avatar>
           <!-- <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>-->
             <v-icon large>mdi-account-circle</v-icon> 
        </v-list-item-avatar>
        <v-list-item-title class="text-h6" >
            {{NombreUsuario}}
            </v-list-item-title>
        </v-list-item>
      <!-- <v-list-group :value="false" prepend-icon="mdi-account-circle">
        <template v-slot:activator>
          <v-list-item-title>{{ NombreUsuario }}</v-list-item-title>
        </template>
        <v-list-item link dense @click="DialogContra = true">
          <v-list-item-title>Cambiar Contraseña</v-list-item-title>

          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item link dense @click="DialogDatosPersonales = true">
          <v-list-item-title>Editar Datos</v-list-item-title>

          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group> -->
    </v-list>
    <v-divider></v-divider>

    <v-list nav style="padding:0px;">
      <v-list-item-group v-model="selectedItem"> <!--  color="#40A5DD" -->
      <v-list-item
          class="items_menu"
          to="/principal"
        >
          <v-list-item-icon>
            <v-icon> mdi-home </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="items_menu"
          v-for="(item, i) in menu"
          :key="i"
          :color="item.color"
          :to="{
            path: item.url,
            name: item.url.substring(1),
            params: { sistema: item },
          }"
          @click="navegacion_menu = false"
        >
          <v-list-item-icon>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title >{{
              item.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <div class="centrar-icono mt-12">
                <v-img
                    contain
                    src="@/assets/fondos/LOGO_MUNI_2024_COLOR.png"
                    aspect-ratio="2"
                    width="150"
                    height="100"
                    :class="{ 'mb-2': navegacion_menu, 'mb-0': !navegacion_menu }"
                ></v-img>
            </div> -->
    <template v-slot:append>
    <v-divider></v-divider>
    <v-list nav dense style="padding:0px;">
      <v-list-item @click="DialogContra = true">
        <v-list-item-icon>
          <v-icon small>mdi-key</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cambiar Contraseña</v-list-item-title>
      </v-list-item>

      <v-list-item @click="DialogDatosPersonales = true">
        <v-list-item-icon>
          <v-icon small>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Editar Datos</v-list-item-title>
      </v-list-item>


      <v-list-item @click="CerrarSesion">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Cerrar Sesión</v-list-item-title>
      </v-list-item>
    </v-list>
    </template>
    <!-- <template v-slot:append>
            <div class="centrar-icono">
                <v-img
                    contain
                    src="@/assets/fondos/LOGO_MUNI_2024_COLOR.png"
                    aspect-ratio="2"
                    width="150"
                    height="100"
                    :class="{ 'mb-2': navegacion_menu, 'mb-0': !navegacion_menu }"
                ></v-img>
            </div>
        </template> -->
    <v-dialog
      v-model="DialogContra"
      persistent
      max-width="400"
      style="z-index: 9999"
    >
      <v-card>
        <v-form ref="formcontra" v-model="validformcontra" lazy-validation>
          <v-card-title class="font-weight-bold">
            <v-row justify="center">
              Cambiar Contraseña
            </v-row>
          </v-card-title>
          <v-card-text>
            <br />
            <v-text-field
              label="CUIT"
              v-model="cuit"
              :rules="[(v) => !!v || '']"
              required
              dense
              filled
              outlined
            ></v-text-field>
            <v-text-field
              label="Contraseña Anterior"
              v-model="clave_vieja"
              id="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :rules="[(v) => !!v || '']"
              required
              filled
              dense
              outlined
            ></v-text-field>
            <v-text-field
              label="Contraseña Nueva"
              v-model="clave_nueva"
              id="password2"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show2 = !show2"
              :type="show2 ? 'text' : 'password'"
              :rules="[(v) => !!v || '']"
              required
              dense
              filled
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dark color="error" class="mr-2" @click="DialogContra = false">
              cancelar
            </v-btn>
            <v-btn color="claro" dark @click="GuardarContra()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DialogDatosPersonales"
      persistent
      max-width="400"
      style="z-index: 9999"
    >
      <v-card>
        <v-form ref="formdatos" v-model="validformdatos" lazy-validation>
          <v-card-title class="font-weight-bold">
            <v-row justify="center">
              Datos Personales
            </v-row>
          </v-card-title>
          <v-card-text>
            <!-- {{datospersonales}} -->
            <br />
            <p style="margin-top: -10px" class="text-subtitle-2">
              Apellido y Nombre:
            </p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.nombre"
                :rules="[(v) => !!v || '']"
                filled
                required
                dense
                outlined
              ></v-text-field>
            </div>
            <p style="margin-top: -10px" class="text-subtitle-2">Teléfono:</p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.telefono_area"
                :rules="[(v) => !!v || '']"
                required
                filled
                prefix="0"
                class="mr-2"
                style="width: 90px"
                dense
                outlined
              ></v-text-field>
              <v-text-field
                v-model="datospersonales.telefono"
                :rules="[(v) => !!v || '']"
                required
                dense
                filled
                outlined
              ></v-text-field>
            </div>
            <!-- <p style="margin-top: -10px" class="text-subtitle-2">Celular:</p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.celular_area"
                :rules="[(v) => !!v || '']"
                required
                prefix="0"
                class="mr-2"
                style="width: 90px"
                dense
                outlined
              ></v-text-field>
              <v-text-field
                v-model="datospersonales.celular"
                :rules="[(v) => !!v || '']"
                required
                prefix="15"
                dense
                outlined
              ></v-text-field>
            </div> -->
            <p style="margin-top: -10px" class="text-subtitle-2">Mail:</p>
            <div class="d-flex align-center" style="margin-top: -10px">
              <v-text-field
                v-model="datospersonales.mail"
                :rules="[(v) => !!v || '']"
                required
                dense
                filled
                outlined
              ></v-text-field>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn dark color="error" class="mr-2" @click="DialogDatosPersonales = false">
              cancelar
            </v-btn>
            <v-btn color="claro" dark @click="GuardarDatosPersonales()">
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>
<style scoped>
.v-list-item--link {
  padding: 0 15px !important;
}
.centrar-icono {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto; /* Para que el icono esté al pie del menú */
}
.items_menu{
  margin-bottom: 0px!important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 20px;
}
.menu{
  background: rgba(22, 47, 61, 1);
  /* background: rgba(0, 0, 0, 0.95); */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
}
</style>

<script>
export default {
  data: () => ({
    menu: [
      // { 
      //   text: "Inmuebles", 
      //   icon: "mdi-home-city", 
      //   url: "/Inmueble", 
      //   color:"#cf4242" 
      // },
      // {
      //   text: "Comercios",
      //   icon: "mdi-store",
      //   color: "#0089ad",
      //   url: "/Comercio",
      // },
      // {
      //   text: "Vehiculos",
      //   icon: "mdi-car",
      //   color: "#985396",
      //   url: "/Vehiculos",
      // },
      // {
      //   text: "Cementerio",
      //   icon: "mdi-bank",
      //   color: "#67b13d",
      //   url: "/Cementerio",
      // },
      // {
      //   text: "Escribanos",
      //   icon: "mdi-scale-balance",
      //   color: "#d09557",
      //   url: "/Escribanos",
      // },
      // {
      //   text: "Obras Privadas",
      //   icon: "mdi-domain",
      //   color: "#5760d0",
      //   url: "/ObrasPrivadas",
      // },
      { 
        text: "Inmuebles", 
        icon: "mdi-home-city", 
        url: "/Inmueble", 
      },
      {
        text: "Comercios",
        icon: "mdi-store",
        url: "/Comercio",
      },
      {
        text: "Vehiculos",
        icon: "mdi-car",
        url: "/Vehiculos",
      },
      {
        text: "Cementerio",
        icon: "mdi-bank",
        url: "/Cementerio",
      },
      {
        text: "Escribanos",
        icon: "mdi-scale-balance",
        url: "/Escribanos",
      },
      {
        text: "Obras Privadas",
        icon: "mdi-domain",
        url: "/ObrasPrivadas",
      },
    ],
    selectedItem2: "",
    selectedItem: "",
    navegacion_menu: true,
    DialogContra: false,

    cuit: "",
    clave_vieja: "",
    clave_nueva: "",
    validformcontra: true,

    DialogDatosPersonales: false,
    validformdatos: true,
    datospersonales: {},
    show1: false,
    show2: false,
    // nombre:""
  }),
  computed: {
    // NombreUsuario() {
    //   if (this.datospersonales!={}) {
    //     return this.datospersonales.nombre;
    //   } else {
    //     return "";
    //   }
    // },
    NombreUsuario(){
      // Obtener los datos del usuario almacenados en el almacenamiento local
      var userData = JSON.parse(localStorage.getItem("userData"));
      return userData.nombre;
    }
  },

  created() {
    // (this.$route.params.datosusuario) ? this.datospersonales = this.$route.params.datosusuario : this.datospersonales = [];
    // (this.$route.params.datosusuario.nombre) ? this.nombre = this.$route.params.datosusuario.nombre : this.nombre='-';
    this.datospersonales = JSON.parse(localStorage.getItem("userData"));
  },

  methods: {
    expandirMenu() {
      this.navegacion_menu = true;
    },
    contraerMenu() {
      this.navegacion_menu = false;
    },
    toggleNavigationMenu() {
      this.navegacion_menu = !this.navegacion_menu;
    },
    GuardarContra() {
      var obj = this;
      this.$ajax
        .patch(`${this.$apitramites}users/changePassword/`, {
          cuit: Number(this.cuit),
          clavevieja: this.clave_vieja,
          clavenueva: this.clave_nueva,
        })
        .then(function (response) {
          console.log("guardar contra", response);
          obj.$swal.fire({
                title: "¡Contraseña modificada con exito!",
                text: "Vuelve a iniciar sesión",
                icon: "success"
              });
          obj.$refs.formcontra.reset();
          obj.DialogContra = false;
          obj.CerrarSesion();
        })
        .catch(function (error) {
          obj.$swal.fire({
                title: "Error",
                text: (error) ? "Datos Incorrectos" : "",
                icon: "error"
              });
        });
    },

    GuardarDatosPersonales() {
      var obj = this;
      this.$ajax
        .post(`${this.$apitramites}users/guardarDatosPersonales/`, {
          nombre: this.datospersonales.nombre,
          telefono_area: this.datospersonales.telefono_area,
          telefono: this.datospersonales.telefono,
          celular_area: this.datospersonales.celular_area,
          celular: this.datospersonales.celular,
          mail: this.datospersonales.mail
        })
        .then(function (response) {
          console.log("guardar datos personales", response);
          obj.$swal.fire({
                title: "¡Editado!",
                text: "Vuelve a iniciar sesión para ver los cambios",
                icon: "success"
              });
          obj.$refs.formdatos.reset();
          obj.DialogDatosPersonales = false;
          obj.CerrarSesion();
        })
        .catch(function (error) {
          obj.$swal.fire({
                title: "Error",
                text: error,
                icon: "error"
              });
        });
    },

    CerrarSesion() {
      localStorage.removeItem("token");
      this.$router.push("/");
    },
  },
};
</script>
