<template>
    <v-dialog
    v-model="DialogCuotasPagas"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar
            dark
            color="error"
            >
            <v-toolbar-title>Cuotas a Pagar</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn
                icon
                @click="DialogCuotasPagas = false;"
                >
                <v-icon>mdi-export</v-icon>
                </v-btn>
            </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
            <v-container>
                <v-row style="margin-top:10px">
                <v-col cols="6" offset="1">
                    <v-card outlined style="height: 292px;">
                    <v-card-title>INFORMACION</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                        <v-col cols="6">
                            <v-text-field
                            :value="datitosPadron(datospagardeuda.datosPadron, 'nropadro')"
                            label="Padrón"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                            :value="datitosPadron(datospagardeuda.datosPadron, 'razosoci')"
                            label="Razón Social"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="margeninputs">
                            <v-text-field
                            :value="datitosPadron(datospagardeuda.datosPadron, 'domi_com')"
                            label="Domicilio"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="margeninputs">
                            <v-text-field
                            :value="datitosPadron(datospagardeuda.datosPadron, 'condicio')"
                            label="Condición"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="margeninputs">
                            <v-text-field
                            :value="datitosPadron(datospagardeuda.datosPadron, 'fec_habi')"
                            label="Fecha de Habilitación"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="12" class="margeninputs">
                            <v-text-field
                            :value="nomenclatura_catastral(datospagardeuda.datosPadron)"
                            label="Nomenclatura Catastral"
                            placeholder="-"
                            outlined
                            readonly
                            ></v-text-field>
                        </v-col> -->
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card outlined style="height: 292px;">
                    <v-card-title>DEUDAS</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                        <v-col cols="6">
                            <h3 class="font-weight-black">Deuda Normal:</h3>
                        </v-col>
                        <v-col cols="6">
                            <h2>${{new Intl.NumberFormat("ES").format(datospagardeuda.totalCuotas)}}</h2>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="font-weight-black">Deuda en Plan:</h3>
                        </v-col>
                        <v-col cols="6">
                            <h2>${{new Intl.NumberFormat("ES").format(datospagardeuda.totalCuotasPlan)}}</h2>
                        </v-col>
                        <v-col cols="6">
                            <h3 class="font-weight-black">Deuda Judicial:</h3>
                        </v-col>
                        <v-col cols="6">
                            <h2>${{new Intl.NumberFormat("ES").format(datospagardeuda.totalCuotasJuicio)}}</h2>
                        </v-col>
                        <v-col cols="5">
                            <h2 class="font-weight-black">Deuda Total:</h2>
                        </v-col>
                        <v-col cols="7">
                            <h1>${{new Intl.NumberFormat("ES").format(suma_deudas)}}</h1>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    </v-card>
                </v-col>
                </v-row>
            </v-container>
            <v-col cols="12">
                <!-- {{this.cunicasseleccionadas}} -->
                <v-card
                outlined
                >
                <v-data-table
                :headers="headerscuotas"
                :items="cuotas"
                item-key="cunica"
                show-select
                class="elevation-1"
                :items-per-page="50"
                
                >
                    <template v-slot:top>
                        <v-toolbar>
                        <v-toolbar-title>Cuotas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <b style="margin-right:10px;">{{cuotas.totalcuota}}</b>
                        <!-- <h3 style="margin-right:10px;">Total a pagar: {{totalapagar}}</h3> -->
                        <!-- <h3 style="margin-right:10px;">{{suma_deudas}}</h3> -->
                        <!-- <v-spacer></v-spacer> -->
                        <v-btn @click="cuotasseleccionadas=[]" style="margin-right:10px;">Limpiar</v-btn>
                        <!-- <v-btn color="primary" @click="PagarCuotas()">PAGAR</v-btn> -->
                        <!-- <v-btn color="primary" @click="DialogPagarDeudas=true">PAGAR</v-btn> -->
                        </v-toolbar>
                    </template>
                    <!-- <template slot="body.append">
                    <tr class="pink--text">
                        <th class="title">Total:</th>
                        <th class="title">{{ SumaCuotasSeleccionadas('totalcuota') }}</th>
                    </tr>
                    </template> -->
                    <template slot="footer">
                    <h1 class="text-right" style="margin-right:10px;">Total: ${{ SumaCuotasSeleccionadas('totalcuota') }}</h1>
                    </template>
                    <template v-slot:[`item.fecha_vencimiento`]="{ item }">{{ item.fecha_ven1 | moment("DD/MM/YYYY")}}</template>
                    <template v-slot:[`item.original_lindo`]="{ item }">{{ new Intl.NumberFormat("ES").format(item.importe)}}</template>
                    <template v-slot:[`item.descuento_lindo`]="{ item }">{{ new Intl.NumberFormat("ES").format(item.descuento)}}</template>
                    <template v-slot:[`item.actualizacion_lindo`]="{ item }">{{ new Intl.NumberFormat("ES").format(item.actualizacion)}}</template>
                    <template v-slot:[`item.recargos_lindo`]="{ item }">{{ new Intl.NumberFormat("ES").format(item.recargo)}}</template>
                    <template v-slot:[`item.total_lindo`]="{ item }">{{ new Intl.NumberFormat("ES").format(item.totalcuota)}}</template>
                </v-data-table>
                </v-card>
            </v-col>

            <!-- <v-col cols="4" offset="8"><h1><b>Total a pagar:  ${{new Intl.NumberFormat("ES").format(totalapagar)}}</b></h1></v-col> -->
            </v-card-text>
        </v-card>
    </v-dialog>
    
</template>

<script>
  // import pagardeuda from "../../views/Pagar_deuda_inmueble";
  export default {
    props:{
        user:Object,
    },
  data: () => ({
    headerscuotas: [
    { text: "CUOTA", align: "start", value: "cuota" },
    { text: "TASA", value: "tasa" },
    { text: "OBRA", value: "obra" },
    { text: "VENCIMIENTO", value: "fecha_vencimiento" },
    { text: "ESTADO", value: "estado" },
    { text: "ORIGINAL", value: "original_lindo" },
    { text: "DESCUENTO", value: "descuento_lindo" },
    { text: "ACTUALIZ.", value: "actualizacion_lindo" },
    { text: "RECARGOS", value: "recargos_lindo" },
    { text: "TOTAL CUOTA", value: "total_lindo" },
    ],
    cuotas: [],
    datospagardeuda: [],
    // totalapagar:"",
    CuentaMunicipal:"",
    DialogCuotasPagas:false
  }),

  computed: {
    
  },

  watch: {
    dialog(){
        if(this.DialogCuotasPagas){
          this.ListadoCuotas();
        }
    },
  },

  created() {
    // this.initialize();
  },


  methods: {

    nomenclatura_catastral(array){
      return `${this.datitosPadron(array, "nc_circ")}-${this.datitosPadron(array, "nc_sec")}-${this.datitosPadron(array, "nc_mzna")}-${this.datitosPadron(array, "nc_letmzna")}-${this.datitosPadron(array, "nc_parc")}-${this.datitosPadron(array, "nc_letparc")}-${this.datitosPadron(array, "nc_uf")}-${this.datitosPadron(array, "nc_chacra")}-${this.datitosPadron(array, "nc_lchacra")}-${this.datitosPadron(array, "nc_quinta")}-${this.datitosPadron(array, "nc_fracci")}`;
    },
    
    datitosPadron(array, dato){
      return (array) ? array[0][dato] : "-";
    },


    ListadoCuotas(){
      this.cuotas=[];
      this.cuotasseleccionadas=[];
      this.datospagardeuda=[];
      var obj = this;
      this.$ajax
      .post(`${this.$apitramites}traerCuotasComercio`, {vencimiento:this.user.vencimiento, cuenta: this.user.padron})
      .then(function(response) {
        console.log("Listado Cuotas comercio", response);
        obj.datospagardeuda=response.data;
        obj.cuotas=response.data.cuotas;
        obj.DialogCuotasPagas=true;
      })
      .catch(function(error) {
        obj.snackbar=true;
        obj.msj= error;
      });
    },
    Sumacuotasapagar(key) {
        // sum data in give key (property)
        return this.cuotasapagar.reduce((a, b) => a + (b[key] || 0), 0)
    },
    SumaCuotasSeleccionadas(key) {
        // sum data in give key (property)
        return this.cuotasseleccionadas.reduce((a, b) => a + (b[key] || 0), 0)
    },
  },
};
</script>