<template>
  <v-app class="gradient">
    <!-- <v-app-bar class="custom-toolbar" flat app>
      <v-toolbar-title>
        <v-img
          contain
          src="@/assets/fondos/LOGO_MUNI_2024_COLOR.png"
          aspect-ratio="2"
          width="78"
          height="70"
          style="margin-left: 10px"
        ></v-img>
      </v-toolbar-title>
      <div class="divtoolbar">
        <h5 class="font-weight-black">MUNICIPALIDAD DE LA COSTA</h5>
        <small>Secretaría de Recursos Públicos</small>
        <small>Dirección de Informática</small>
      </div>
    </v-app-bar> -->
    <div class="contenedor_principal">
      <!-- <div class="gradient"></div> -->
      <v-col cols="5" class="background-secundario">
        <div class="content-container">
          <p class="text-h3 bold-text text-right letra" style="color: #4f798f">
            Trámites con autogestión
          </p>
          <div class="texto">
            <p style="text-align: right">
              Queremos facilitarte la forma de realizar
            </p>
            <p style="text-align: right">tus trámites a través de la Web.</p>
            <p style="text-align: right">
              Si es tu primer ingreso deberás registrarte
            </p>
            <p style="text-align: right">
              (por única vez) para obtener la clave.
            </p>
            <p style="text-align: right">
              Sólo te llevará unos minutos, es fácil y sencillo.
            </p>
          </div>
          <v-row justify="end" style="margin-top: 10px">
            <v-col md="3">
              <p
                class="font-weight-black font-italic texto"
                style="color: #4f798f"
              >
                1- Registro
              </p>
              <p class="font-italic text-subtitle-2">
                Para realizar los tramites con autogestión deberá estar
                previamente registrado, completando por única vez el formulario
                de registro.
              </p>
            </v-col>
            <v-divider
              class="mx-4"
              vertical
              style="
                border-width: 1px !important;
                border-color: #4f798f;
                height: 200px;
                margin-top: 20px;
              "
            ></v-divider>
            <v-col md="3">
              <p
                class="font-weight-black font-italic texto"
                style="color: #4f798f"
              >
                2- Validación
              </p>
              <p class="font-italic text-subtitle-2">
                Validar sus datos ingresando al link que le será enviado al
                correo electrónico informado en el formulario.
              </p>
            </v-col>
            <v-divider
              class="mx-4"
              vertical
              style="
                border-width: 1px !important;
                border-color: #4f798f;
                height: 200px;
                margin-top: 20px;
              "
            ></v-divider>
            <v-col md="3">
              <p
                class="font-weight-black font-italic texto"
                style="color: #4f798f"
              >
                3- Ingreso a Trámites
              </p>
              <p class="font-italic text-subtitle-2">
                Una vez validado sus datos, ingresar a la nueva plataforma de
                trámites, con su cuit y contraseña. Y podrá comenzar a disfrutar
                de las nuevas opciones y servicios.
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="7" class="container-registro">
        <!-- <v-container fluid> -->
        <!-- <v-row justify="center" class="mt-4">
            <p class="text-h4 bold-text letra">FORMULARIO DE REGISTRO</p>
          </v-row> -->
        <v-card flat class="mt-6">
          <!-- <v-card height="auto" style="margin: 30px auto;">
          <v-card-text> -->
          <v-card-title class="text-h4 bold-text letra justify-center">
            FORMULARIO DE REGISTRO
          </v-card-title>
          <v-form
            ref="formregistro"
            v-model="validformregistro"
            lazy-validation
          >
            <!-- <v-container> -->

            <v-container>
              <v-row>
                <v-col cols="5">
                  <v-row no-gutters>
                    <!-- <div class="text-subtitle-2">CUIT:</div> -->
                    <!-- <v-row style="margin-top: -20px"> -->
                    <small
                      >(Si lo desconoce, lo puede consultar
                      <a
                        href="http://servicioswww.anses.gob.ar/ConstanciadeCuil2/Inicio.aspx"
                        target="_blank"
                      >
                        AQUÍ
                      </a>
                      )
                    </small>
                    <!-- </v-row> -->
                  </v-row>
                  <v-text-field
                    :rules="[
                      (v) => !!v || '',
                      (v) => this.cuitverificado || 'Cuit inválido',
                    ]"
                    @change="verificarCuit"
                    v-mask="'##-########-#'"
                    v-model="cuit"
                    label="CUIT"
                    ref="refcuit"
                    required
                    outlined
                    filled
                    dense
                    auto-grow
                    class="mt-2"
                  />
                </v-col>
                <v-col cols="7">
                  <div class="text-subtitle-2">TIPO DE CUIT:</div>
                  <v-radio-group
                    v-model="tipocuit"
                    row
                    :rules="[(v) => !!v || '']"
                    required
                  >
                    <v-radio label="Femenino" value="F"></v-radio>
                    <v-radio label="Masculino" value="M"></v-radio>
                    <v-radio label="Empresa" value="E"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row style="margin-top: -30px">
                <v-col md="6">
                  <!-- <div class="text-subtitle-2">APELLIDO Y NOMBRE:</div> -->
                  <v-text-field
                    v-model="nombrecompleto"
                    label="APELLIDO Y NOMBRE"
                    :rules="[(v) => !!v || '']"
                    required
                    outlined
                    filled
                    auto-grow
                    dense
                    class="mt-2"
                  ></v-text-field>
                </v-col>

                <v-col md="6">
                  <!-- <div class="text-subtitle-2">TELÉFONO:</div> -->
                  <v-row>
                    <v-col md="5" class="mt-2">
                      <v-text-field
                        label="PREFIJO"
                        placeholder="-"
                        v-model="prefijotel"
                        :rules="[(v) => !!v || '']"
                        outlined
                        filled
                        auto-grow
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="7" class="mt-2">
                      <v-text-field
                        label="NÚMERO"
                        placeholder="-"
                        v-model="numerotel"
                        :rules="[(v) => !!v || '']"
                        required
                        outlined
                        filled
                        auto-grow
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row style="margin-top: -30px">
                <v-col md="6">
                  <!-- <div class="text-subtitle-2">MAIL:</div> -->
                  <v-text-field
                    class="mt-2"
                    v-model="mail"
                    label="MAIL"
                    :rules="[
                      (v) => !!v || '',
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        'Mail incorrecto',
                    ]"
                    required
                    outlined
                    filled
                    auto-grow
                    dense
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <!-- <div class="text-subtitle-2">CONFIRMAR MAIL:</div> -->
                  <v-text-field
                    class="mt-2"
                    v-model="confirmarmail"
                    label="CONFIRMAR MAIL"
                    :rules="[
                      (v) => !!v || '',
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        'Mail incorrecto',
                      (v) => v == this.mail || 'Los mails no coinciden',
                    ]"
                    required
                    outlined
                    filled
                    auto-grow
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -30px">
                <v-col md="6">
                  <!-- <div class="text-subtitle-2">CONTRASEÑA:</div> -->
                  <v-text-field
                    v-model="contrasenia"
                    label="CONTRASEÑA"
                    :rules="[(v) => !!v || '']"
                    required
                    outlined
                    filled
                    auto-grow
                    dense
                    class="mt-2"
                    :append-icon="vercontra ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="vercontra = !vercontra"
                    :type="vercontra ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <!-- <div class="text-subtitle-2">CONFIRMAR CONTRASEÑA:</div> -->
                  <v-text-field
                    v-model="confirmarcontrasenia"
                    label="CONFIRMAR CONTRASEÑA"
                    :rules="[
                      (v) => !!v || '',
                      (v) =>
                        v == this.contrasenia || 'Las contraseñas no coinciden',
                    ]"
                    required
                    outlined
                    filled
                    auto-grow
                    dense
                    class="mt-2"
                    :append-icon="vercontra2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="vercontra2 = !vercontra2"
                    :type="vercontra2 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <!-- </v-card-text> -->

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="error" width="140" large @click="Volver()"> cancelar </v-btn>
            <v-btn large color="#4f798f" @click="GuardarRegistro()" dark small>
              Guardar Datos
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- </v-container> -->
      </v-col>
      <!-- <v-divider
          style="
            border-width: 5px !important;
            border-color: #40A5DD !important;
            height: 100%;
          "
        ></v-divider> -->
    </div>
    <!-- </v-row> -->
    <!-- </v-container> -->
    <!-- </container> -->

    <!-- <v-footer class="custom-footer" app>
      <v-row justify="center">
        <v-col>
          <div class="text-center">
            <v-icon x-large color="#40A5DD" style="font-size: 45px"
              >mdi-home</v-icon
            >
            <br />
            <small>Inmueble</small>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <v-icon x-large color="#40A5DD" style="font-size: 45px"
              >mdi-file-document</v-icon
            >
            <br />
            <small>Comercio</small>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <v-icon x-large color="#40A5DD" style="font-size: 45px"
              >mdi-car</v-icon
            >
            <br />
            <small>Vehículo</small>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <v-icon
              x-large
              color="#40A5DD"
              style="font-size: 45px; margin-left: 15px"
              >mdi-paperclip</v-icon
            >
            <br />
            <small>Obras Privadas</small>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <v-icon x-large color="#40A5DD" style="font-size: 45px;"
              >mdi-scale-balance</v-icon
            >
            <br />
            <small>Escribanos</small>
          </div>
        </v-col>
        <v-col>
          <div class="text-center">
            <v-icon x-large color="#40A5DD" style="font-size: 45px"
              >mdi-bank</v-icon
            >
            <br />
            <small>Cementerio</small>
          </div>
        </v-col>
      </v-row>
      <p class="text-center">
          <b>Centro de Atención a Distancia(CAD): Mail atencionadistancia@lacosta.gob.ar / Teléfonos 02246-433025 / 433003 / 433071 / 433036</b>
      </p>
    </v-footer> -->

    <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
      {{ msj }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarconfirm = false"
        >
          cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      validformregistro: true,

      snackbarconfirm: false,
      timeout: 3000,
      msj: "",

      cuitolvidecontra: "",
      cuitverificado: true,
      tipocuit: null,
      cuit: "",
      nombrecompleto: "",
      prefijotel: "",
      numerotel: "",
      prefijocel: "",
      numerocel: "",
      mail: "",
      confirmarmail: "",
      contrasenia: "",
      confirmarcontrasenia: "",
      empresacel: "",
      empresascel: ["Tuenti", "Movistar", "Claro", "Personal", "Nextel"],
      vercontra: false,
      vercontra2: false,
    };
  },

  created() {},

  computed: {
    ArrayRegistro() {
      return {
        cuit: Number(this.cuit.split("-").join("")),
        nombre: this.nombrecompleto,
        tipo_cuit: this.tipocuit,
        telefono_area: this.prefijotel,
        telefono: this.numerotel,
        celular_area: this.prefijocel,
        celular: this.numerocel,
        mail: this.confirmarmail,
        clave: this.confirmarcontrasenia,
        celular_empresa: this.empresacel,
      };
    },
  },

  methods: {
    verificarCuit() {
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}users/controlCuit/${this.cuit}`)
        .then(function (response) {
          console.log("Verificar cuit", response);
          if (response.data.usuario) {
            obj.$swal.fire({
              title: "¡Atención!",
              text: "Este cuit ya esta registrado en el sistema.",
              icon: "info",
            });
          }
          obj.cuitverificado = response.data.cuitValido;
          obj.$refs.refcuit.validate();
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },
    GuardarRegistro() {
      if (this.$refs.formregistro.validate()) {
        var obj = this;
        this.$ajax
          .post(`${this.$apitramites}auth/register`, this.ArrayRegistro)
          .then(function (response) {
            console.log("Guardar registro", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: response.data.msj,
              icon: "success",
            });
            obj.$refs.formregistro.reset();
            obj.$router.push({ path: "/" });
          })
          .catch(function (error) {
            console.log(error);
            obj.$swal.fire({
              title: "Error",
              // text: (error.message=='USER_EXIST') ? 'Este usuario ya esta creado' : error,
              text: "Error al enviar mail",
              icon: "error",
            });
          });
      }
    },
    Volver() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
/* .label-text {
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.85;
} */
.texto {
  font-size: large;
}
.letra {
  font-family: "Scout", fantasy !important;
}
.v-application p {
  margin-bottom: 3px;
}
.divtoolbar {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.contenedor_principal {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  /* background: url('../assets/fondos/fondo_login.jpeg') center/cover no-repeat; */
}
.custom-toolbar {
  min-height: 78px;
  max-height: 78px;
  /* border-bottom: 10px solid #40A5DD; */
  border-bottom: 10px solid #4f798f;
}
.custom-footer {
  min-height: 100px;
  /* border-top: 10px solid #40A5DD; */
  border-top: 10px solid #4f798f;
}
.container-registro {
  padding-top: 20; /* Añade esta línea para eliminar el padding top */
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  /* background-color: #40a5dd29; */
  /* background-color: #61849694; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.background-secundario {
  height: 100vh;
  background-color: #e0f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: url('../assets/fondos/fondo_login.jpeg') center/cover no-repeat; */
  /* background-color: #40a5dd29; */
}
.content-container {
  width: 100%;
  text-align: right;
  padding: 10px;
}
.gradient {
  background: linear-gradient(to right, #e0f7fa, #4f798f);
  height: 100%;
  width: 100%;
}
</style>
