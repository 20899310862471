<template>
<!-- <v-app> -->
    <!-- <v-dialog
    v-model="DialogAdhesionBoleta"
    width="1000"
    persistent
    > -->
        <v-form @submit.prevent="EnviarSolicitud" ref="formadhesionboleta" v-model="validformadhesionboleta">
        <!-- <v-card > -->
            <v-card-title class="headline">
                <v-row justify="center" style="margin-top:5px;">
                    <b>Adhesion a boleta electrónica</b>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <!-- <v-row>
                        <v-col cols="6">
                            <p class="label-text">Apellido y Nombre</p>
                            <v-text-field
                                name="nombrecompleto"
                                placeholder=""
                                solo
                                flat
                                auto-grow
                                dense
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                            />

                        </v-col>
                        <v-col cols="3">
                            <p class="label-text">DNI</p>
                            <v-text-field
                                name="dni"
                                placeholder=""
                                type="number"
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="3">
                            <p class="label-text">Teléfono</p>
                            <v-text-field
                                name="telefono"
                                placeholder=""
                                type="number"
                                solo
                                flat
                                auto-grow
                                dense
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row style="margin-top: -30px;">
                        <v-col cols="4">
                            <p class="label-text">Mail</p>
                            <v-text-field
                                name="mail"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="3">
                            <p class="label-text">Provincia</p>
                            <v-text-field
                                name="provincia"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="3">
                            <p class="label-text">Localidad</p>
                            <v-text-field
                                name="localidad"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="2">
                            <p class="label-text">CP</p>
                            <v-text-field
                                name="cp"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                    </v-row> 
                    <v-row style="margin-top: -30px;">
                        <v-col cols="6">
                            <p class="label-text">Calle</p>
                            <v-text-field
                                name="calle"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="2">
                            <p class="label-text">N°</p>
                            <v-text-field
                                name="numero"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="2">
                            <p class="label-text">Piso</p>
                            <v-text-field
                                name="piso"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                        <v-col cols="2">
                            <p class="label-text">Depto</p>
                            <v-text-field
                                name="depto"
                                placeholder=""
                                :rules="[
                                            (v) =>
                                            !!v ||
                                            'Campo requerido',
                                        ]"
                                required
                                solo
                                dense
                                flat
                                auto-grow
                            />
                        </v-col>
                    </v-row> -->
                    <v-text-field
                        name="mail"
                        label="Mail"
                        placeholder=""
                        :rules="[
                                    (v) =>
                                    !!v ||
                                    'Campo requerido',
                                ]"
                        required
                        filled
                        outlined
                        dense
                        class="mt-4"
                    />
                    <v-row>
                        <v-col cols="12">
                            <p  style="color: #2196f3;" >Autorizo a la Municipalidad de La Costa a enviar via E-Mail las boletas de la Tasa de Servicios Generales.
                            Ademas quedara sin efecto el envio de la boleta por correo convencional.
                            La presente solicitud queda sujeta a confirmacion.</p>
                        </v-col>
                    </v-row>       
                        
                    
                    
                </v-container>
                
            </v-card-text>
            <!-- <v-divider></v-divider> -->
            <v-card-actions class="justify-center">
                <!-- <v-spacer></v-spacer>
                <v-btn @click="DialogAdhesionBoleta=false">
                    Cancelar
                </v-btn> -->
                <v-btn dark
            color="error" class="mr-2" @click="vaciarExpanded()">
                cancelar
                </v-btn>
                <v-btn dark color="claro" type="submit" id="btnenviarsolicitud">
                    Enviar Solicitud
                </v-btn>
            </v-card-actions>
        <!-- </v-card> -->
        <v-snackbar
        v-model="snackbarconfirm"
        :timeout="timeout"
        top
        >
        {{ msj }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbarconfirm = false"
            >
            Cerrar
            </v-btn>
        </template>
        </v-snackbar>
        </v-form>
        
    <!-- </v-dialog> -->
<!-- </v-app> -->
</template>

<script>
  // import pagardeuda from "../../views/Pagar_deuda_inmueble";
export default {
    props:{
        user:Object,
    },
    data: () => ({
        validformadhesionboleta:true,
        snackbarconfirm:false,
        timeout:3000,
        msj:""
    }),

    watch: {

    },

    methods: {
        EnviarSolicitud(event){
            console.log("asdas", Object.fromEntries(new FormData(event.target)).mail)
            if (this.$refs.formadhesionboleta.validate()) {
                var obj = this;
                this.$ajax
                .post(`${this.$apitramites}inmuebles/reciboElectronico`, {cuenta: this.user.datosinmueble.cuenta, mail: Object.fromEntries(new FormData(event.target)).mail})
                .then(function(response) {
                    console.log("Enviar solicitud boleta electronica", response);
                    obj.$swal.fire({
                        title: "¡Exito!",
                        text: "Solicitud enviada",
                        icon: "success"
                    });
                    obj.$refs.formadhesionboleta.reset();
                    obj.vaciarExpanded();
                })
                .catch(function(error) {
                    obj.snackbar=true;
                    obj.msj= error;
                });
            }
        },

        vaciarExpanded(){
        this.$emit('vaciarExpanded')
        }
    },

    
}
</script>
<style scoped>
.label-text {
    margin-bottom: 4px;
    /* font-weight: bold; */
    font-size: 15px;
    opacity: 0.85;
}
.theme--light.v-text-field--solo >>> .v-input__control > .v-input__slot {
    background-color: #afaaaa ;
}
.theme--dark.v-text-field--solo >>> .v-input__control > .v-input__slot {
    background-color: #636363 ;
}
</style>