<template>
  <v-container fluid class="background-container">
    <v-row>
      <v-col cols="8" class="altura d-flex align-center justify-center">
        <!-- <div class="overlay"></div> -->
        <div class="content">
          <!-- <p class="text-h2 font-weight-black text-center titulo">
            Portal de TRÁMITES
          </p> -->
        </div>
      </v-col>
      <v-col cols="4" class="background-container2">
        <div class="columna mb-4">
        <v-img src="@/assets/fondos/LOGO_LA_COSTA_BLANCO.png" max-height="200" max-width="170" class="mb-8"></v-img>
        <!-- <v-card class="login-card" outlined> -->
          <div class="text-center mb-4 titulo_portal">Portal de Trámites</div>
          <v-form>
            <v-text-field
              label="CUIT"
              dense
              outlined
              filled
              dark
              v-model="usuariologin"
              
              v-mask="'##-########-#'"
            >
              <template v-slot:prepend-inner>
                <v-icon>mdi-account</v-icon>
              </template>
            </v-text-field>
            <v-text-field
                id="password"
                name="password"
                dense
                outlined
                filled
                dark
                :append-icon="vercontra ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="vercontra = !vercontra"
                :type="vercontra ? 'text' : 'password'"
                v-model="contrasenialogin"
                label="CONTRASEÑA"
                @keyup.enter="EntrarLogin()"
                style="margin-top:-10px;"
              >
                <template v-slot:prepend-inner>
                  <v-icon>mdi-lock</v-icon>
                </template>
              </v-text-field>
            <div class="d-flex justify-center mb-4" style="margin-top: -20px;">
                <v-btn
                  text
                  class="font-weight-medium text-right "
                  x-small
                  @click="dialogOlvideContra = true"
                  style="text-decoration: underline;"
                  dark
                >
                  OLVIDÉ MI CONTRASEÑA
                </v-btn>
              </div>
            <!-- Botones de acción -->
            <!-- <v-btn block color="primary" class="mt-8" @click="Entrar()">Ingresar</v-btn>
            <v-btn block outlined class="mt-4" @click="FormularioRegistro()">Registrarse</v-btn> -->
            <v-btn dark class="button-tra mb-2" @click="EntrarLogin()" color="rgb(46, 97, 125)" depressed>
              Ingresar
            </v-btn>
            <v-btn dark class="button-tra" @click="FormularioRegistro()" color="#424242" depressed>
              REGISTRARSE
            </v-btn>
            
          </v-form>
        <!-- </v-card> -->
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogOlvideContra" width="400" transition="dialog-top-transition" persistent>
      <v-card min-height="200" style="border-radius: 10px;">
        <v-card-title class="headline">Olvidé mi contraseña</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" style="margin-top:10px">
              <v-text-field
                label="CUIT"
                dense
                filled
                outlined
                v-model="cuitolvidecontra"
                :rules="[
                  (v) => !!v || 'Falta el Cuit'
                ]"
                v-mask="'##-########-#'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="height: 20px;">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialogOlvideContra = false">Cancelar</v-btn>
          <v-btn color="claro" dark @click="OlvideContra">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
      {{ msj }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbarconfirm = false">Cerrar</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data(){
      return {
         fondo: "",

         usuariologin: "",
         contrasenialogin: "",

         dialogOlvideContra:false,
         validformregistro:true,

         snackbarconfirm:false,
         timeout:3000,
         msj:"",

         cuitolvidecontra:"",
         cuitverificado: true,
         tipocuit:null,
         cuit:"",
         nombrecompleto:"",
         prefijotel:"",
         numerotel:"",
         prefijocel:"",
         numerocel:"",
         mail:"",
         confirmarmail:"",
         contrasenia:"",
         confirmarcontrasenia:"",
         empresacel:"",
         empresascel:["Tuenti","Movistar","Claro", "Personal","Nextel"],
         vercontra: false,
         datosusuario:{}
         
      }
   },
   methods:{
    FormularioRegistro(){
        this.$router.push({ path: '/formulario_registro' });
    },
    
    EntrarLogin(){
        var cuit = Number(this.usuariologin.split("-").join(""));
        var obj =this;
        this.$ajax
        .post(`${this.$apitramites}auth/login`, {cuit: cuit, clave: this.contrasenialogin})
        .then(function(response) {
          if(response.data){
              // obj.$swal.fire({
                // title: "¡Logueado con exito!",
                // text: "Inmueble eliminado con éxito",
              //   icon: "success"
              // });
              console.log("Login usuario", response)
              obj.datosusuario=response.data.user[0]
              console.log("datos usuario", obj.datosusuario)
              localStorage.setItem("userData", JSON.stringify(response.data.user[0]));
              localStorage.setItem("cuit", obj.usuariologin)
              localStorage.setItem("token",response.data.token)
              obj.$ajax.defaults.headers.common['Authorization']= "Bearer "+response.data.token
              obj.$router.push({ path: '/principal', name: 'principal', params: { datosusuario: obj.datosusuario } })
          }else{
              console.log("Login usuario2", response)
              obj.$swal.fire({
                  title: "Error",
                  text: response.data.respuesta,
                  icon: "error"
                });
          }
        })
        .catch(function(error) {
            console.log("login error", error);
            // alert("Usuario o contraseña incorrecta")
            obj.$swal.fire({
              icon: "error",
              title: "Usuario o contraseña incorrecta",

              // title: "Are you sure?",
              // text: "You won't be able to revert this!",
              // icon: "warning",
              // showCancelButton: true,
              // confirmButtonText: "Yes, delete it!",
              // cancelButtonText: "No, cancel!",
              // reverseButtons: true
            });
            
        });
    },
    OlvideContra(){
        var obj = this;
        this.$ajax
        .patch(`${this.$apitramites}users/forgotPassword/${Number(this.cuitolvidecontra.split("-").join(""))}` )
        .then(function(response) {
          console.log("olvide contra", response);
          obj.$swal.fire({
                title: "¡Exito!",
                text: response.data.msj,
                icon: "success"
              });
          obj.dialogOlvideContra=false;
          obj.cuitolvidecontra="";
        })
        .catch(function(error) {
          console.log(error)
          obj.$swal.fire({
              title: "Error",
              text: "Error al enviar mail, intente nuevamente.",
              icon: "error"
            });
        });
    },
   }
};
</script>

<style scoped>
.background-container {
  /* position: relative;
  overflow: hidden; */
  height: 100vh;
  background: url('../assets/fondos/fondo_login.jpeg') center/cover no-repeat;
}
.altura{
  height: 100vh;
}
/* .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
} */
.content {
  /* position: absolute; */
  top: 50%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
  width: 100%;
  color: white;
}
.login-card {
  /* width: 300px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */

  max-width: 320px;
  margin: auto;
  margin-top: 20px;
  padding: 25px;
  border-radius: 25px;
  border: 10px solid rgb(49 135 213 / 48%);
}
.icon-inside-text-field {
  font-weight: bold;
  letter-spacing: -1px;
}
.titulo_portal{
    color: white;
    font-size: 35px;
    font-weight: 600;
    /* font-family: italic; */
    /* letter-spacing: .1666666667em; */

}
.button-tra {
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: -0.5px;
}
.columna{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.background-container2{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

</style>