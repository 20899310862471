<template>
  <!-- <v-card> -->
  <v-form
    @submit.prevent="EnviarSolicitud"
    ref="formadhesiondebito"
    v-model="validformadhesiondebito"
  >
    <!-- <v-toolbar dark dense flat>
        <v-toolbar-title>ADHESIÓN A DÉBITO AUTOMÁTICO</v-toolbar-title>
      </v-toolbar> -->
    <v-toolbar dark flat>
      <v-toolbar-title>ADHESIÓN A DÉBITO AUTOMÁTICO</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mb-8">
      <v-row justify="center">
        <v-col md="12" sm="12">
          <v-card outlined>
            <v-card-title
              class="d-flex align-center justify-center font-weight-black"
            >
              AUTORIZACIÓN
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <b
                      >Autorizo a la Municipalidad de La Costa a debitar los
                      importes correspondientes al pago de mi:</b
                    >
                  </v-col>
                  <v-col cols="12" style="margin-top: -20px">
                    <v-radio-group
                      v-model="optionCuentaTipo"
                      name="tipoCuenta"
                      row
                      :rules="[(v) => !!v || 'Seleccione un tipo de cuenta']"
                      required
                    >
                      <v-radio
                        label="TARJETA DE CRÉDITO / DÉBITO"
                        value="credito"
                      ></v-radio>
                      <v-radio
                        label="CUENTA BANCARIA"
                        value="bancario"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row style="margin-top: -22px">
                  <v-col cols="12">
                    <b>Los importes correspondientes al pago de:</b>
                  </v-col>
                  <v-col cols="12" style="margin-top: -20px">
                    <v-radio-group 
                      v-model="optionCuota" 
                      name="tipoCuota" 
                      :rules="[(v) => !!v || 'Seleccione un tipo de cuota']" 
                      required
                    >
                      <v-radio
                        label="LA CUOTA MENSUAL DE LA TASA DE SERVICIOS GENERALES"
                        value="1"
                      ></v-radio>
                      <!-- <v-radio
                              label="LA CUOTA ANUAL DE LA TASA DE SERVICIOS GENERALES (efectuados en enero)"
                              value="3"
                            ></v-radio> -->
                      <v-radio
                        label="LA CUOTA SEMESTRAL DE LA TASA DE SERVICIOS GENERALES (efectuados en enero y febrero)"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- </v-col> -->
        <v-col md="12" sm="12" v-if="optionCuentaTipo == 'credito'">
          <v-card outlined>
            <v-card-title
              class="d-flex align-center justify-center font-weight-black"
            >
              TARJETA DE CRÉDITO/DÉBITO
            </v-card-title>
            <v-card-text>
              <v-radio-group
                v-model="tarjeta"
                name="tipoTarjeta"
                :rules="[(v) => !!v || 'Seleccione un tipo de tarjeta']"
                required
                row
                class="mt-5"
              >
                <v-row>
                  <b>TARJETA:</b>
                  <v-radio
                    style="margin-left: 10px"
                    label="VISA"
                    value="1"
                  ></v-radio>
                  <v-radio label="VISA DÉBITO" value="4"></v-radio>
                  <v-radio label="MASTERCARD" value="2"></v-radio>
                  <v-radio label="AMERICAN EXPRESS" value="3"></v-radio>
                </v-row>
              </v-radio-group>

              <v-autocomplete
                v-model="bancotarjeta"
                name="codigoBanco"
                outlined
                filled
                dense
                class="mt-5"
                :items="bancos"
                label="Seleccione un banco"
                :rules="[(v) => !!v || 'Seleccione un banco']"
                required
                item-value="t_cod"
                item-text="t_deta"
              >
              </v-autocomplete>

              <v-text-field
                label="Nº DE TARJETA"
                name="numeroTarjeta"
                :rules="[(v) => !!v || 'Falta el numero de tarjeta']"
                required
                outlined
                filled
                dense
                placeholder="-"
                maxlength="16"
              ></v-text-field>

              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col md="3">
                      <h3 style="margin-top: 25px">Emisión:</h3>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        style="margin-top: 20px"
                        name="emisionAnio"
                        :rules="[(v) => !!v || 'Falta fecha emision']"
                        required
                        placeholder="2017"
                        outlined
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <h3 style="margin-top: 25px">/</h3>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        style="margin-top: 20px"
                        name="emisionMes"
                        :rules="[(v) => !!v || 'Falta fecha emision']"
                        required
                        placeholder="01"
                        outlined
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row>
                    <v-col md="4">
                      <h3 style="margin-top: 25px">Vencimiento:</h3>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        style="margin-top: 20px"
                        name="emisionAnioVencimiento"
                        :rules="[(v) => !!v || 'Falta fecha de vencimiento']"
                        required
                        placeholder="2017"
                        outlined
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <h3 style="margin-top: 25px">/</h3>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        style="margin-top: 20px"
                        name="emisionMesVencimiento"
                        :rules="[(v) => !!v || 'Falta fecha de vencimiento']"
                        required
                        placeholder="01"
                        outlined
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12" sm="12" v-if="optionCuentaTipo == 'bancario'">
          <v-card outlined>
            <v-card-title
              class="d-flex align-center justify-center font-weight-black"
            >
              CUENTA BANCARIA
            </v-card-title>
            <v-card-text>
              <v-radio-group
                v-model="tipocuenta"
                name="tipoBancaria"
                class="mt-5"
                row
              >
                <v-row>
                  <b class="ml-4">TIPO DE CUENTA:</b>
                  <v-radio
                    style="margin-left: 10px"
                    label="CUENTA CORRIENTE"
                    value="1"
                  ></v-radio>
                  <v-radio label="CAJA DE AHORRO" value="2"></v-radio>
                </v-row>
              </v-radio-group>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    item-value="t_cod"
                    item-text="t_deta"
                    v-model="bancocuenta"
                    name="codigoBanco"
                    :items="bancos"
                    label="Seleccione un banco"
                    :rules="[(v) => !!v || 'Seleccione un banco']"
                    required
                    class="mt-4"
                    filled
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Nº DE CUENTA"
                    name="cuentaBancaria"
                    :rules="[(v) => !!v || 'Falta el numero de cuenta']"
                    required
                    placeholder="-"
                    class="mt-4"
                    filled
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" style="margin-top: -25px">
                  <v-text-field
                    label="SUCURSAL"
                    name="sucursal"
                    :rules="[(v) => !!v || 'Falta la sucursal']"
                    required
                    placeholder="-"
                    class="mt-0"
                    filled
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" style="margin-top: -25px">
                  <v-text-field
                    label="CBU"
                    name="cbu"
                    :rules="[(v) => !!v || 'Falta el CBU']"
                    required
                    placeholder="-"
                    filled
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- <v-col md="10" sm="12" v-if="optionCuentaTipo == 'credito' || optionCuentaTipo == 'bancario'">
            <v-card outlined>
              <v-card-title class="d-flex align-center justify-center font-weight-black">
                TITULAR DE CUENTA BANCARIA
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col md="6" sm="12">
                    <v-text-field
                      label="DNI"
                      name="DNI"
                      :rules="[(v) => !!v || 'Falta el DNI']"
                      required
                      placeholder="-"
                      outlined
                      filled
                      dense
                    ></v-text-field>
                    <v-text-field
                      label="NOMBRE"
                      name="nombre"
                      :rules="[(v) => !!v || 'Falta el nombre']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="DOMICILIO"
                      name="domicilio"
                      :rules="[(v) => !!v || 'Falta el domicilio']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="PROVINCIA"
                      name="provincia"
                      :rules="[(v) => !!v || 'Falta la provincia']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="MAIL"
                      name="mail"
                      :rules="[(v) => !!v || 'Falta el mail']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" sm="12"> 
                    <v-text-field
                      label="LOCALIDAD"
                      name="localidad"
                      :rules="[(v) => !!v || 'Falta la localidad']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="CP"
                      name="cp"
                      :rules="[(v) => !!v || 'Falta el cp']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="TELÉFONO"
                      name="telefono"
                      :rules="[(v) => !!v || 'Falta el teléfono']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                    <v-text-field
                      label="CELULAR"
                      name="celular"
                      :rules="[(v) => !!v || 'Falta el celular']"
                      required
                      outlined
                      filled
                      dense
                      placeholder="-"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col> -->
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-center" style="padding-bottom: 20px">
      <v-btn dark color="error" large class="mr-2" @click="vaciarExpanded()">
        cancelar
      </v-btn>
      <v-btn dark color="claro" large type="submit" id="btnenviarsolicitud">
        Enviar Solicitud
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbarconfirm" :timeout="timeout" top>
      {{ msj }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbarconfirm = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>

  <!-- </v-card> -->
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data: () => ({
    snackbarconfirm: false,
    timeout: 3000,
    msj: "",
    optionCuentaTipo: null,
    optionCuota: null,
    tipocuenta: null,
    bancotarjeta: "",
    bancocuenta: "",
    bancos: [],
    tarjeta: null,
    validformadhesiondebito: true,
    dialogEliminarBanco: false,
  }),

  watch: {
    // dialog() {
    //   if (this.DialogAdhesionDebito) {
    //     this.TraerDatosAdhesionDebito();
    //   }
    // },
  },

  mounted() {
    this.Iniciar();
  },

  methods: {
    Iniciar() {
      this.LlenarBancos();
    },

    TraerDatosAdhesionDebito() {
      // this.DialogAdhesionDebito = true;
      // this.LlenarBancos();
    },

    LlenarBancos() {
      console.log("usuario", this.user);
      var obj = this;
      this.$ajax
        .get(`${this.$apitramites}funciones/bancos`)
        .then(function (response) {
          console.log("bancos", response);
          obj.bancos = response.data;
        })
        .catch(function (error) {
          obj.snackbarconfirm = true;
          obj.msj = error;
        });
    },

    EnviarSolicitud(event) {
      const formData = Object.fromEntries(new FormData(event.target));
      formData.cuenta = this.user.datosinmueble.cuenta;
      console.log("enviar solicitud", formData);

      if (this.$refs.formadhesiondebito.validate()) {
        var obj = this;
        this.$ajax
          .post(`${this.$apitramites}inmuebles/debitoAutomatico`, formData)
          .then(function (response) {
            console.log("Enviar solicitud debito automatico", response);
            obj.$swal.fire({
              title: "¡Exito!",
              text: "Solicitud enviada con exito",
              icon: "success",
            });
            // obj.DialogAdhesionDebito = false;
            obj.$refs.formadhesiondebito.reset();
            obj.vaciarExpanded();
          })
          .catch(function (error) {
            obj.$swal.fire({
              title: "Error",
              text: error,
              icon: "error",
            });
          });
      }
    },
    vaciarExpanded() {
      this.$emit("vaciarExpanded");
    },
  },
};
</script>
