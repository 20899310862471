<template>
  <v-app>
    <div class="loading-general" :class="($globalData.isLoading) ? 'activo' : ''">
      <v-progress-circular
      :size="100"
      :width="5"
      color="#00B0FF"
      indeterminate
    ></v-progress-circular>
    </div>
    <v-main>
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {
    
  }
}
</script>
<style>
body {
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap')
  
}
.v-application{ 
  font-family: "Roboto Condensed", sans-serif!important;
  font-optical-sizing: auto;
  font-style: normal;
}
.v-overlay--active {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
.v-overlay__scrim {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}
.titulo{
  display: flex;
  flex-direction: column;
  margin-left:20px;
}
.swal2-popup {
  font-family: 'Roboto', sans-serif; /* Cambia 'Roboto' por el tipo de letra que desees */
}
.swal2-container{
  z-index: 999999;
}
.swal2-confirm.btn {
  background-color: rgb(98 133 152)!important
}
</style>
<style scoped>
  .titulo{
    display: flex;
    flex-direction: column;
    margin-left:20px;
  }
</style>
<style lang="scss">


#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.loading-general {
    position: fixed;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgb(255 255 255 / 50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -5;
    transition: all .3s ease-in-out;
}
.loading-general.activo {
    z-index: 9999999999999;
    opacity: 1;
}
</style>
